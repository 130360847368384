import React, { CSSProperties, useContext } from 'react';

import { ThemeContext } from 'contexts/ThemeContextProvider';
import TransitionText, { transitionClassNames } from '../TransitionText/TransitionText';

import './IntroSection.scss';


const IntroText = () => {
  let introTitle = "Hey, I'm Robert!";
  let title = (
    <h1>{introTitle}</h1>
  );

  let introSubtitle = "I'm a software developer.";
  let subtitle = (
    <h1>{introSubtitle}</h1>
  );

  let introText = `I enjoy solving all types of problems
    ranging from front-end to back-end development. I love
    getting to work with new librarys and technologies, and 
    really love it when I can get them working! The satisfaction
    I get from solving problems is what keeps me developing.`;
  let introTextEle = (
    <h2>{introText}</h2>
  );

  const { themeStyles, isLightTheme } = useContext(ThemeContext);
  const extraThemeStyles: CSSProperties = {
    transition: 'all 0.50s linear'
  };
  if (!isLightTheme) {
    extraThemeStyles.background = '#72b9f7';
    extraThemeStyles.color = '#3B4856';
  }

  return (
    <div className='long-section' style={extraThemeStyles}>
      <div className='container'>
        <div className='column'>
          <TransitionText 
            element={title}
            transitionClassName={transitionClassNames.default}
          />
          <br />
          <TransitionText 
            element={subtitle}
            transitionClassName={transitionClassNames.default}
          />
          <br />
          <TransitionText 
            element={introTextEle}
            transitionClassName={transitionClassNames.default}
          />
        </div>
      </div>
    </div>
  );
};

const OutroText = () => {
  let outroTitleText = "Interested in more?";
  let outroTitle = (
    <h1>{outroTitleText}</h1>
  );

  let outroSubtitleText = "Check out the other parts of the site.";
  let outroSubtitle = (
    <h1>{outroSubtitleText}</h1>
  );

  let outroText = `I plan to attach different web apps to
  subdomains on my domain as I continue to explore different
  ideas and technologies. Check out some of my previous work, or reach
  out to me by checking out the other parts of the site as well!`;
  let outroTextEle = (
    <h2>{outroText}</h2>
  );

  let outroFarewellText = "Thanks for stopping by!"
  let outroFarwell = (
    <h1>{outroFarewellText}</h1>
  );

  const { themeStyles, isLightTheme } = useContext(ThemeContext);
  const extraThemeStyles: CSSProperties = {
    transition: 'all 0.50s linear'
  };
  if (!isLightTheme) {
    extraThemeStyles.background = '#72b9f7';
    extraThemeStyles.color = '#3B4856';
  }

  return (
    <div className='long-section reverse' style={extraThemeStyles}>
      <div className='container'>
        <div className='column'>
          <TransitionText 
            element={outroTitle}
            transitionClassName={transitionClassNames.default}
          />
          <br />
          <TransitionText 
            element={outroSubtitle}
            transitionClassName={transitionClassNames.default}
          />
          <br />
          <TransitionText 
            element={outroTextEle}
            transitionClassName={transitionClassNames.default}
          />
          <br />
          <br />
          <TransitionText 
            element={outroFarwell}
            transitionClassName={transitionClassNames.default}
          />
        </div>
      </div>
    </div>
  );
};

const IntroSection = () => {
  // Frontend section
  let feIcon = (
    <i className='fas fa-laptop-code fa-3x'></i>
  );
  let feTitle = (
    <h1>Front-End Development</h1>  
  );
  let feDesc = (
    <div className='info'>
      I like to leverage frameworks
      to make interesting designs
      function on the web.
    </div>
  );
  let feLang = (
    <p>Languages:</p>
  );
  let feLangContent = (
    <div className='info'>
      HTML, CSS, Sass, JS, Python
    </div>
  );
  let feTech = (
    <p>Technologies:</p>
  );
  let feTechContent = (
    <div className='info'>
      React, Flask, Vue
    </div>
  );
  let feContent = [feIcon, feTitle, feDesc,
    feLang, feLangContent, feTech, feTechContent];

  // Backend section
  let beIcon = (
    <i className='fas fa-server fa-3x'></i>
  );
  let beTitle = (
    <h1>Back-End Development</h1>
  );
  let beDesc = (
    <div className='info'>
      Optimizing the performance of 
      data flow to and from systems
      leveraging the best technology
      for the job.
    </div>
  );
  let beLangContent = (
    <div className='info'>
      Java, Python, Go
    </div>
  );
  let beTechContent = (
    <div className='info'>
      Django, Flask, Tornado, Spring
    </div>
  );
  let beContent = [beIcon, beTitle, beDesc,
    feLang, beLangContent, feTech, beTechContent];

  // Libraries Section
  let libIcon = (
    <i className='fas fa-book-open fa-3x'></i>
  );
  let libTitle = (
    <h1>Utilizing Libraries</h1>
  );
  let libDesc = (
    <div className='info'>
      I enjoy experimenting with new
      libraries and technologies to 
      find better ways to solve problems.
    </div>
  );
  let libIntrests = (
    <p>Areas of Interest:</p>
  );
  let libIntrestsContent = (
    <ul>
      <li>Socket Programming</li>
      <li>Async Programming</li>
      <li>DevOps</li>
      <li>Containerization</li>
      <li>TDD</li>
      <li>Blockchain Development</li>
      <li>Cross Platform Mobile Solutions</li>
    </ul>
  );
  let libLibs = (
    <p>Libraries:</p>
  );
  let libLibsContent = (
    <div className='info'>
      SocketIO, Futures, Tornado, <br />
      Gitlab, Github, Build Pipelines, <br />
      ElasticSearch, Docker, <br />
      Docker-Compose, Kubernetes, <br />
      Go-Ethereum, Solidity, Flutter <br />
      JUnit, Solenium
    </div>
  );
  let libContent = [libIcon, libTitle, libDesc,
    libIntrests, libIntrestsContent, libLibs, libLibsContent];

  // Arch Section
  let archIcon = (
    <i className='fas fa-sitemap fa-3x'></i>
  );
  let archTitle = (
    <h1>Architectural Design</h1>
  );
  let archDesc = (
    <div className='info'>
      Learning about design patterns
      and building scaleable solutions
      are a large area of my focus.
    </div>
  );
  let archConc = (
    <p>Concepts:</p>
  );
  let archConcContent = (
    <div className='info'>
      Design Patterns, Interface &amp; Concrete Implementations,
      Decoupled &amp; Cohesive Objects, TDD, In-Memory Database Testing
    </div>
  );
  let archContent = [archIcon, archTitle, archDesc,
    archConc, archConcContent];

  const { themeStyles } = useContext(ThemeContext);
  const extraThemeStyles = {
    ...themeStyles,
    borderRadius: '12px',
    boxShadow: 'inheirit',
    borderBottom: 'inheirit'
  };

  return(
    <div className="intro-section" style={themeStyles}>
      <IntroText />
      <div className='skills-section'>
        <div className='slim-container'>
          <div className='box'>
            <div className='columns' style={extraThemeStyles}>
              <div className='column'>
                {feContent.map((element, index) => (
                  <TransitionText 
                    element={element}
                    key={index}
                    transitionClassName={transitionClassNames.left}
                  />
                ))}
              </div>
              <div className='column'>
                {beContent.map((element, index) => (
                  <TransitionText 
                    element={element}
                    key={index}
                    transitionClassName={transitionClassNames.right}
                  />
                ))}
              </div>
              <div className='column'>
                {libContent.map((element, index) => (
                  <TransitionText 
                    element={element}
                    key={index}
                    transitionClassName={transitionClassNames.left}
                  />
                ))}
              </div>
              <div className='column'>
                {archContent.map((element, index) => (
                  <TransitionText 
                    element={element}
                    key={index}
                    transitionClassName={transitionClassNames.right}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <OutroText />
    </div>
  );
}

export default IntroSection;
