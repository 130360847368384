import React, { useState, useRef, useEffect } from 'react';

import { Switch, Route, withRouter, BrowserRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import AboutPage from '../Pages/AboutPage';
import HomePage from '../Pages/HomePage';
import Page404 from '../Pages/Page404';
import WorkPage from '../Pages/WorkPage';
import ContactPage from '../Pages/ContactPage';

import './RouterContainer.scss';


const RouterContainer = ({ location }: RouteComponentProps) => {
  const [isPerspective, setPerspective] = useState(false);
  const [isFirstLoad, setFirstLoad] = useState(true);
  const TRANSITION_TIME = 1000;

  let routeSectionRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // Prevents timer on initial load
    if (isFirstLoad) {
      setFirstLoad(false);
      return;
    }

    setPerspective(true);
    window.scrollTo(0, 0);

    const timer = setTimeout(() => {
      setPerspective(false);
      window.scrollTo(0, 0);
    }, TRANSITION_TIME);
    return () => clearTimeout(timer);
  }, [location]);

  let rcStyle = {};
  if (isPerspective) {
    rcStyle = {
      perspective: '1000px'
    };
  }

  return (
    <div 
      className='router-container'
      style={rcStyle}
    >
      <TransitionGroup>
        <CSSTransition
          key={location.key}
          timeout={{ enter: TRANSITION_TIME, exit: TRANSITION_TIME }}
          classNames='slide'
          >
            <div 
              className='route-section'
              ref={routeSectionRef}
            >
              <BrowserRouter>
                <Switch location={location}>
                  <Route path='/about' component={AboutPage} />
                  <Route path='/work' component={WorkPage} />
                  {/* <Route path='/contact' component={ContactPage} />   */}
                  {/* <Route component={Page404} /> */}
                  <Route path='/' component={HomePage} />
                  <Route path='*' component={HomePage} />
                </Switch>
              </BrowserRouter>
            </div>
          </CSSTransition>
      </TransitionGroup>
    </div>
  );
}

export default withRouter(RouterContainer);
