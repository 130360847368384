import React from 'react';

import ThemeContextProvider from './contexts/ThemeContextProvider';
import Header from './components/Header/Header';
import RouterContainer from './components/RouterContainer/RouterContainer';
import './app.css';


const App = () => {
  return (
    <div className='app-container'>
      <div className='page-container'>
        <ThemeContextProvider>
          <Header />
          <RouterContainer />
        </ThemeContextProvider>
      </div>
    </div>
  );
}

export default App;
