import React from 'react';
import { Link } from 'react-router-dom';

import './Footer.scss';

const Footer = () => {

  return (
    <div className='footer-section'>
      <div className='container'>
        <Link className='footer-logo' to="/">
          robs<div>dev</div>
        </Link>
        <div className='info'>
          Engineering solutions &amp; 
          chasing new innovations 
        </div>
        <div className='social'>
          <a href='https://github.com/ottingbob' className='button'>
            <div className='icon'>
              <i className="fab fa-github fa-lg"></i>
            </div>
          </a>
          <a href='https://www.linkedin.com/in/robert-otting/' className='button'>
            <div className='icon'>
              <i className="fab fa-linkedin-in fa-lg"></i>
            </div>
          </a>
        </div>
        <div className='credit'>
          Site design by myself 
          &nbsp;<i className="far fa-copyright"></i> 2023
        </div>
      </div>
    </div>
  );
}

export default Footer;
