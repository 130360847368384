import React, { useContext } from 'react';

import { ThemeContext } from 'contexts/ThemeContextProvider';
import Footer from '../Footer/Footer';
import Hero from '../Hero/Hero';
import IntroSection from '../Intro/IntroSection';


const HomePage = () => {
  const { themeStyles } = useContext(ThemeContext);

  return (
    <div className='home-page' style={themeStyles}>
      <Hero />
      <div className='white-bg' style={themeStyles}/>
      <IntroSection />
      <Footer />
    </div>
  );
};

export default HomePage;
