import React, { useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { Waypoint } from 'react-waypoint';


export const transitionClassNames = {
  default: 'text-area',
  left: 'left-text',
  right: 'right-text'
};

type TransitionTextProps = {
  element: JSX.Element,
  transitionClassName: string
};

const TransitionText = ({ element, transitionClassName }: TransitionTextProps) => {
  const [isTransition, setTransition] = useState(false)

  const waypointEnter = () => {
    setTransition(true);
  }

  return (
    <div>
      <Waypoint 
        onEnter={waypointEnter}
        bottomOffset={'20%'}
      >
        <div></div>
      </Waypoint>
      <CSSTransition 
        in={isTransition}
        timeout={550}
        classNames={transitionClassName}
        unmountOnExit
      >
        {element}
      </CSSTransition>
    </div>
  );
};

export default TransitionText;
