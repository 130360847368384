import React, { useState, createContext } from 'react';

type Props = {
  children: any[]
};

export const ThemeContext = createContext<any|null>(null);

const ThemeContextProvider: React.FC<Props> = ({ children }: Props) => {
  const [isLightTheme, setLightTheme] = useState(true);

  const lightStyles = {
    transition: 'all 0.50s linear',
    // background: '#eee',
    background: '#fff',
    // color: '#555'
    color: '#000'
  };

  const darkStyles = {
    transition: 'all 0.50s linear',
    background: '#555',
    color: '#ddd',
    borderColor: '#ddd'
  };

  const themeStyles = isLightTheme ? lightStyles : darkStyles;

  const toggleTheme = () => {
    setLightTheme(!isLightTheme);
  }

  return (
    <ThemeContext.Provider
      value={{ isLightTheme, toggleTheme, themeStyles }}
    >
      {children}
    </ThemeContext.Provider>
  )
};

export default ThemeContextProvider;
