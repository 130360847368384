import React from 'react';
import styled from 'styled-components';

import WorkHeader from '../WorkHeader/WorkHeader';
import WorkWith from 'components/WorkWith/WorkWith';
import Footer from '../Footer/Footer';


const WorkWithContainer = styled.div`
  height: 70vh;
  background: #fff;
  margin-top: -20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`;

const NameContainer = styled.div`
  flex: 0 0 100%;

  h1 {
    font-weight: bold;
    font-size: 3em;
    margin: 0 auto;
    padding-top: 3em;
    width: fit-content;
    color: #000;
    text-align: center;
  }
`;

const WorkPage = () => {
  return (
    <div style={{ position: 'relative' }}>
      {/* <WorkHeader /> */}
      <WorkWithContainer>
        <NameContainer>
          <h1>hey — I'm robert</h1>
        </NameContainer>
        <WorkWith />
      </WorkWithContainer>
      <Footer />
    </div>
  );
};

export default WorkPage;
