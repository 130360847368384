import React from 'react';
import { Link } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

import { ThemeContext } from 'contexts/ThemeContextProvider';


type NavigationData = {
  className: string,
  to: string,
  name: string
};

const navigationData: NavigationData[] = [
  {
    className: 'n1',
    to: '/',
    name: 'Home'
  },
  {
    className: 'n2',
    to: '/work',
    name: 'Work'
  },
  {
    className: 'n3',
    to: '/about',
    name: 'About'
  },
  // {
  //   className: 'n4',
  //   to: '/contact',
  //   name: 'Contact'
  // },
];

type NavigationProps = {
  isMenuOpen: boolean,
  toggleCallback: () => void
};

const Navigation = ({ isMenuOpen, toggleCallback }: NavigationProps) => {
  return (
    <div className="navigation-wrap">
      {/* <div className="navigation-bg"></div> */}
      <div className="in">
        <CSSTransition
          in={isMenuOpen}
          timeout={700}
          classNames='menu'
          unmountOnExit>
          <ul className="navigation">
            {navigationData.map(({ className, to, name }) => {
              return (
                <li 
                  className={className}
                  key={name}
                >
                  <Link
                    onClick={() => toggleCallback()} 
                    to={to}
                  >
                    {name}
                  </Link>
                </li>
              );
            })}
            <li className='n4'></li>
            <li className="n5"></li>
            {/* <li className="n6"></li> */}
            <NavigationSquare parentClassName={'hook'} classPrefix={'t'} />
            <li className="n7"></li>
            <li className="n8"></li>
            {/* <li className="n9"></li> */}
            <NavigationSquare parentClassName={'corner'} classPrefix={'d'} />
          </ul>
        </CSSTransition>
      </div>
    </div>
  );
};

type NavigationSquareProps = {
  parentClassName: string,
  classPrefix: string
};

const NavigationSquare = ({ parentClassName, classPrefix }: NavigationSquareProps) => {
  const divClassNames = [];
  for (let i = 0; i < 10; i++) {
    divClassNames.push(`${classPrefix}${i}`);
  }
  return (
    <div className={parentClassName}>
      {divClassNames.map((className) => {
        return (
          <div
            className={className}
            key={className}
          />
        );
      })}
    </div>
  );
};

export default Navigation;
