import React, { useContext } from 'react';

import { ThemeContext } from 'contexts/ThemeContextProvider';
import './Hero.scss';


const Hero = () => {
  const { themeStyles } = useContext(ThemeContext);

  return (
    <div className="home-hero" style={themeStyles}>
      <h1>
        <div>robert</div>
        <div>otting</div>
      </h1>
    </div>
  );
};

export default Hero;
