import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import ReactToggle from 'react-toggle';
import 'react-toggle/style.css';

import { ThemeContext } from 'contexts/ThemeContextProvider';
import Navigation from './Navigation';
import Toggle from './Toggle';

import './Header.scss';

const Header = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const { isLightTheme, toggleTheme, themeStyles } = useContext(ThemeContext);

  const toggleClick = () => {
    setMenuOpen(!isMenuOpen);
  };

  const checkMenu = () => {
    if (isMenuOpen) setMenuOpen(!isMenuOpen);
  };

  let headerClasses = ["header-simple"];
  let outerClass = ["header-content"];

  if (isMenuOpen) {
    headerClasses.push("is-menu-open");
    outerClass.push('is-menu-open');
    document.body.style.overflow = "hidden";
  } else { 
    document.body.style.overflow = "visible";
  }

  return (
    <div className="header-container">
      <div className={outerClass.join(' ')}>
        <div className="header-bg" style={themeStyles}>
          <div className='header-wrapper' style={themeStyles}>
            <Link to="/" className="logo-wrap" onClick={() => checkMenu()}>
              <div className="table">
                <div className="cell">
                  <div className="logo" style={themeStyles}>
                    robs<div>dev</div>
                  </div> 
                </div>
              </div>
            </Link>
            {/* Continue to integrate... it would be a lot of work... */}
            {/* <div className='dark-mode-toggle-wrapper'>
              <ReactToggle 
                icons={{
                  checked: <i className='fas fa-sun' />,
                  unchecked: <i className='fas fa-moon' />
                }}
                defaultChecked={isLightTheme}
                onChange={() => toggleTheme()}
              />
            </div> */}
            <Toggle toggleCallback={toggleClick} />
          </div>
        </div>
      </div>
      <div className={headerClasses.join(' ')}>
        <Navigation
          isMenuOpen={isMenuOpen}
          toggleCallback={toggleClick}
        />
        <div className="header-bottom">
        </div>
      </div>
    </div>
  );
}

export default Header;
