import React, { useState } from 'react';

import Footer from '../Footer/Footer';
import './AboutPage.scss'

// const aboutContent = [
//   {
//     title: 'Music',
//     content: `Music has been a big part of my life since I started playing
//       when I was 11. Originally starting on the baritone I used music as a
//       way to develop my creativty and expression through an art form. I have
//       played in a number of bands throughout my education and most recently
//       have pick up bass guitar. In Dallas I have played with two different groups
//       on bass and always excited to work on new projects.`,
//     iconClass: 'fas fa-music',
//   },
//   {
//     title: 'Fitness',
//     content: `After stopping sports when I entered high school I still wanted
//       to be able to stay active in my free time. Over the remainder of high school
//       I rock climbed for 3 years. After moving on from my rock climbing group I 
//       started to lift more regularly. Today I try to exercise a few times a week but 
//       I want to get into more activities such as hiking or back into rock climbing.`,
//     iconClass: 'fas fa-weight-hanging',
//   },
//   {
//     title: 'Cooking',
//     content: `In addition to staying active I like to compliment my healthy lifestyle
//       with good nutrition. As a result I have started to cook more of my own meals and
//       have a better understanding of what I am putting into my body. Although it takes
//       more time to prepare and clean up it's sometimes nice to be able to use cooking 
//       as an activity to clear my mind.`,
//     iconClass: 'fas fa-utensils',
//   },
//   {
//     title: 'Gardening',
//     content: `Once I moved to Texas and got used to the weather I wanted to take advantage
//       of all the free heat we get. To utilize this to its full potential I have started to 
//       work on my green thumb as I grow more as a resident in the lone star state. I like
//       gardening because it is a very iterative process as I figure out what works and what
//       doesn't every growing season.`,
//     iconClass: 'fas fa-seedling',
//   },
// ]

const aboutContent = [
  {
    title: 'Music',
    content: `Whether it comes down to listening or playing I really enjoy music. I play the
    bass guitar and used to play trombone and baritone.`,
    iconClass: 'fas fa-music',
  },
  {
    title: 'Exercise',
    content: `I really enjoy getting outside on a hike, rock climbing with others,
    or spending some time decompressing in the gym with friends after work`,
    iconClass: 'fas fa-weight-hanging',
  },
  {
    title: 'Cooking',
    content: `In addition to eating good food I like to make it sometimes too. Dallas is great
    because we have a lot of different cultures here which has helped me to expand the realm
    of possibilities when it comes to what I make.`,
    iconClass: 'fas fa-utensils',
  },
  {
    title: 'Gardening',
    content: `Although I haven't done it recently upon moving to Texas I took advantage of the
    weather to work on a garden. It was interesting to test out which plants enjoyed the heat
    and which ones did not.`,
    iconClass: 'fas fa-seedling',
  },
];

const AboutPage = () => {
  const [activeIndex, setActiveIndex] = useState(-1);

  const handleInfoClick = (index: number) => {
    // if activeIndex === current element switch to -1
    let matchedIndex = activeIndex === index ? -1 : index;
    setActiveIndex(matchedIndex);
  }

  return (
    <div className='about-container'>
      <div className='header'>
        <h1>hey — I'm robert</h1>
        <div className='about-box'>
          <h3>I love crafting new skills.</h3>
          <p>
            I am a developer that enjoys working on
            a wide variety of projects. I have a few other
            hobbies that involve not sitting behind a 
            computer screen. Check them out below:
          </p>
          <ul>
            {aboutContent.map((content, index) => {
              // Logic to determine element
              let liClasses = '';
              if (activeIndex === index) {
                liClasses = 'open';
              }

              return (
                <li
                  className={liClasses}
                  key={index}
                  onClick={() => handleInfoClick(index)}
                >
                  <div className='info-header'>
                    <i className={content.iconClass} />
                    <h4>{content.title}</h4>
                    <span className='expand' />
                  </div>
                  <div className='info-content'>
                    <div className='ic-wrapper'>
                      {content.content}
                    </div>
                  </div>
                </li>
              )
            })}
          </ul>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default AboutPage;
