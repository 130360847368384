import React, { Component } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';

type WorkWithProps = {
  className?: string,
};

// type WorkWithState = {
//   currentWord: number,
// };

// const WorkWith = styled(class extends Component<WorkWithProps, WorkWithState> {
const WorkWith = styled(class extends Component<WorkWithProps> {
  words: string[] = [];
  currentWordIdx?: number;
  // timeout?: number;
  timeout?: NodeJS.Timeout;
  wordRefs: Map<number, Word | null> = new Map<number, Word | null>();

  constructor(props: WorkWithProps) {
    super(props);

    this.words = [
      'confidence', 'micro-services', 'golang', 'java', 'spring-boot', 'python', 'react',
      'kafka', 'linux', 'bash', 'docker', 'kubernetes', 'open-source', 'databases', 'CI/CD'
    ];
  }

  componentDidMount() {
    // this.nextWord(1000);
    this.nextWord(2000);
  }

  componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  animateLetterOut(wordIdx: number, progression: number) {
    setTimeout(() => {
      this.wordRefs.get(wordIdx)?.setProgression(false, progression);
    }, progression * 120);
  }

  animateLetterIn(wordIdx: number, progression: number) {
    setTimeout(() => {
      this.wordRefs.get(wordIdx)?.setProgression(true, progression);
    }, 340 + (progression * 120));
  }

  nextWord(timeShown = 2000) {
    let currentWord = this.currentWordIdx !== undefined ?
      this.words[this.currentWordIdx] : undefined;
    let nextWordIdx = this.currentWordIdx === undefined || this.currentWordIdx === this.words.length - 1 ?
      0 : ((this.currentWordIdx || 0) + 1);
    let nextWord = this.words[nextWordIdx];

    if (this.currentWordIdx !== undefined && currentWord !== undefined) {
      for (let i = 0; i <= currentWord.length; i++) {
        this.animateLetterOut(this.currentWordIdx, i);
      }
    }

    this.wordRefs.get(nextWordIdx)?.setShow(true);
    for (let i = 0; i <= nextWord.length; i++) {
      this.animateLetterIn(nextWordIdx, i);
    }

    this.currentWordIdx = nextWordIdx;
    this.timeout = setTimeout(() => this.nextWord(), timeShown);
  }

  render() {
    let { className } = this.props;

    return (
      <div className={ className }>
        <span>I work with&nbsp;</span>
        <span>
          {
            this.words.map((word, idx) => 
              <Word 
                ref={ref => this.wordRefs.set(idx, ref)}
                {...{ key: word, idx, word }}
              />
            )
          }
        </span>
      </div>
    )
  }
})`

// font-family: 'Open Sans', sans-serif;
font-weight: 600;
font-size: 40px;
width: 100%;

@media (max-width: 500px) {
  font-size: 25px;
  margin-top: 1rem;
}

margin-top: 20px;
// margin-bottom: 20px;
margin-bottom: 5em;

display: flex;
justify-content: center;

// @media (max-width: 768px) {
//   font-size: 30px;
// }

height: 40px;

> span {
  &:last-child {
    position: relative;
    width: 190px;

    @media (max-width: 768px) {
      display: block;
      width: 55%;
    }
  }
  display: inline-block;
  vertical-align: top;
  margin: 0;
}

.word {
  position: absolute;
  width: 270px;
  opacity: 0;

  @media (max-width: 500px) {
    width: unset;
  }

  &.show
  {
    opacity: 1;
  }
}

.letter {
  display: inline-block;
  position: relative;
  float: left;
  transform: translateZ(25px);
  transform-origin: 50% 50% 25px;
}

.letter.out {
  transform: rotateX(90deg);
  transition: transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.letter.behind {
  transform: rotateX(-90deg);
}

.letter.in {
  transform: rotateX(0deg);
  transition: transform 0.28s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

/*
.wisteria {
  color: #8e44ad;
}

.belize {
  color: #2980b9;
}

.pomegranate {
  color: #c0392b;
}

.green {
  color: #16a085;
}

.midnight {
  color: #7fd5ea;
}
*/

// Generated accents thru: https://mycolor.space/?hex=%23E95657
.red-accent-1 {
  color: #E95657;
}

.maroon-accent-2 {
  color: #CB4D7C;
}

.plum-accent-3 {
  color: #9C528F;
}

.purple-accent-4 {
  color: #67568C;
}

.navy-accent-5 {
  color: #3D5277;
}

.blue-accent-6 {
  color: #166EC4;
}

.sky-blue-accent-7 {
  color: #0072B4;
}

`;


// const COLORS: string[] = ['wisteria', 'belize', 'pomegranate', 'green', 'midnight'];
const COLORS: string[] = ['red-accent-1', 'maroon-accent-2', 'plum-accent-3', 'purple-accent-4',
  'navy-accent-5', 'blue-accent-6', 'sky-blue-accent-7'];

type WordProps = {
  className?: string,
  idx: number,
  word: string,
};

type WordState = {
  progression: number,
  show: boolean, 
  movingIn: boolean,
};

class Word extends Component<WordProps, WordState> {
  constructor(props: WordProps) {
    super(props);

    this.state = {
      movingIn: true,
      progression: 0,
      show: false
    };
  }

  setShow(show: boolean) {
    this.setState({ show });
  }

  setProgression(movingIn: boolean, progression: number) {
    this.setState({
      movingIn,
      progression
    });
  }

  render() {
    let { className, idx, word } = this.props;
    let { movingIn, progression, show } = this.state;

    return (
      <span
        className={classNames({
          [ COLORS[idx % COLORS.length] ]: true,
          className,
          'word': true,
          show,
        })}
      >
        {
          [...word].map((letter, letterIdx) => {
            return (
              <span
                className={classNames({
                  'behind': movingIn,
                  'letter': true,
                  'in': movingIn && progression > letterIdx,
                  'out': !movingIn && progression > letterIdx,
                })}
              >
                {letter}
              </span>
            );
          })
        }
      </span>
    )
  }
}

export default WorkWith;
