import React, { useContext } from 'react';

import { ThemeContext } from 'contexts/ThemeContextProvider';


type ToggleProps = {
  toggleCallback: () => void
};

const Toggle = ({ toggleCallback }: ToggleProps) => {
  const { isLightTheme } = useContext(ThemeContext);

  const classNames = ['t', 'b'];
  const toggleCompsClasses = [];
  for (let i = 1; i < 10; i++) {
    if (i === 3) {
      toggleCompsClasses.push(['t', 'r', `t${i}`]);  
      continue;
    }
    toggleCompsClasses.push([...classNames, `t${i}`]);
  }

  return (
    <div className="toggle" onClick={() => toggleCallback()}>
      <div className="table">
        <div className="cell">
          <div className="toggle-in">
            <div className="in">
              {toggleCompsClasses.map((currentClasses, idx) => {

                // Conditionally render theme
                const themeStyles = { background: '' };
                if (idx === 2 && !isLightTheme) {
                  // themeStyles.background = '#24467d';
                  // themeStyles.background = '#89b2f5';
                  themeStyles.background = '#72b9f7';
                } else if (idx !== 2 && !isLightTheme) {
                  themeStyles.background = '#ddd';
                }

                return (
                  <div 
                    className={currentClasses.join(' ')}
                    key={idx}
                    style={themeStyles}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Toggle;
